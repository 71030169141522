<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { statistiquesService } from "../../../../services/statistiques.service";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { filesBaseUrl } from "../../../../constants/config";
import { artisteService } from "../../../../services/artiste.service";
import { customFunction } from "../../../../utils/customFunction";
/**
 * Blank page component
 */
export default {
  page: {
    title: "Statistique des écoutes",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, Multiselect },
  data() {
    return {
      title: "Statistique des écoutes",
      items: [],
      transactions: [],
      filesBaseUrl,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "count",
      sortDesc: true,
      fields: [
        { key: "libelle", label: "Titre", sortable: true },
        { key: "artiste", label: 'Artiste', sortable: true },
        { key: "count", label: "Nombre total d'écoutes", sortable: true }
      ],
      selectedEtat: {},
      artistes: [],
      daterange: '',
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactions.length;
    }
  },
  mounted() {
    this.getTransactions();
    // Set the initial number of items
    this.totalRows = this.transactions.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    isArtiste() {
      return customFunction.destoreParam('yendiuartiste') != null;
    },
    async getTransactions() {
      this.transactions = (await statistiquesService.get()).ecoutes.morceaux;
      this.artistes = await artisteService.get();
    },
    async chooseDate(e) {
      const params = `startDate=${new Date(e[0]).toISOString()}&endDate=${new Date(e[1]).toISOString()}`;
      this.transactions = (await statistiquesService.get(params)).ecoutes.morceaux;
    },
    async clearable() {
      this.transactions = (await statistiquesService.get()).ecoutes.morceaux;
    },
    async selectEvent(e) {
      const dates = this.daterange[0] != null ? `startDate=${new Date(this.daterange[0]).toISOString()}&endDate=${new Date(this.daterange[1]).toISOString()}&` : '?';
      const params = `${dates}`;
      this.transactions = (await statistiquesService.get(params)).ecoutes.morceaux;
      if(e != null) this.transactions = this.transactions.filter(t => t.morceaux.featers.some(r => r.id == e.id));
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-md-3">
        <label for="">Date</label>
        <date-picker v-model="daterange" format="DD/MM/YYYY" range append-to-body confirm @clear="clearable"
          @confirm="chooseDate" placeholder="Selectionner votre rangé de date"></date-picker>
      </div>
      <div v-if="!isArtiste()" class="col-md-3">
        <label for="">Artistes</label>
        <multiselect @input="selectEvent" v-model="selectedEtat" track-by="id" :options="artistes" label="nom_scene"
          :multiple="false"></multiselect>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage"
                      size="sm" :options="pageOptions"></b-form-select>entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table :items="transactions" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template v-slot:cell(libelle)="r">
                  <img :src="`${filesBaseUrl}/morceaux/${r.item.morceaux.image}`" height="40" alt="">
                  {{ r.item.morceaux.libelle }}
                </template>
                <template v-slot:cell(artiste)="d">
                  <span class="badge" :class="{
                                'bg-primary': true,
                            }">
                    {{ d.item.morceaux.featers[0].nom_scene }}
                  </span>
                </template>
                <template v-slot:cell(count)="r">
                  <span class="badge" :class="{
                                'bg-primary': true,
                            }"> {{ r.item.count }} </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>