import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";
import { customFunction } from "../utils/customFunction";

export const statistiquesService = {
    get
};


async function get(param) {
    let artiste = customFunction.destoreParam('yendiuartiste');
    let p = param != null && param != '' ? param : '';
    try {
        let rets = artiste != null ? await axiosInstance.get(`${baseUrl}/statistiques?artiste=${artiste.id}&${p}`) : await axiosInstance.get(`${baseUrl}/statistiques?${p}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}
